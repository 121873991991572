import {
    Component, ElementRef, OnInit, AfterViewInit, AfterContentInit, ViewContainerRef,
    ViewChild, OnDestroy, ChangeDetectorRef
} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Company, BankAccount} from '../../../data/company';
import {CompanyService} from '../../../services/company.service';
import { CompanyHistoryComponent } from '../company-history/company-history.component';
import { OkDialogComponent } from '../../ok-dialog/ok-dialog.component';
import { merge, of } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { PaginatedAnswer } from 'src/app/data/response';
import { TranslateService } from '@ngx-translate/core';
import {CompanyBankaccountComponent} from "../company-bankaccount/company-bankaccount.component";
import {CompanyRepresentativesComponent} from "../company-representatives/company-representatives.component";
import {DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS} from "@angular/material/core";
import {DateParsePipe} from "../../../pipes/date-parse.pipe";
import {DateFormatPipe} from "../../../pipes/date-format.pipe";
import {CredentialsService} from "../../../services/credentials.service";

@Component({
  selector: 'app-company-editor',
  templateUrl: './company-editor.component.html',
  styleUrls: ['./company-editor.component.less']
})
export class CompanyEditorComponent implements AfterContentInit , OnInit , AfterViewInit {

  companyForm: FormGroup = this.formBuilder.group({
    id: null,
    long_name: [null, [Validators.required]],
    tax_number: [null, [Validators.required]],
    hu_tax_number: [null, [Validators.required]],
    currency_iso_code: null,
    short_name: null,
    email_address: [null, [Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
    mainActivity: null,
    valid_from: null,
    primary_bank_account_account: null,
    primary_representative_tax_number: null,
    signatoryAuditor: [null, [Validators.required]],
    contractType: [null],
    contractStatus: [null],
    decision: [null],
    status: [null],
    company_registration_number: null,
    customer_status_sid: null,
    is_active: true,
    postal_address: null,
    postal_code: null,
/*    area: null,
    building_name: null,
    building_number: null,
    city: null,
    door_number: null,
    floor: null,
    post_office_box_location: null,
    post_office_number: null,
    postal_address: null,
    postal_code: null,
    province_code: null,
    region: null,
    street_kilometer: null,
    street_name: null,
    street_number: null*/
  });

  _countryDims: any;
  get countryDims() { return this._countryDims };
  set countryDims(value: any) {
    this._countryDims = value;
  }

  _signatoryAuditorSelectionItems: any;
  get signatoryAuditorSelectionItems() { return this._signatoryAuditorSelectionItems };
  set signatoryAuditorSelectionItems(value: any) {
    this._signatoryAuditorSelectionItems = value;
  }

  _decisionSelectionItems: any;
  get decisionSelectionItems() { return this._decisionSelectionItems };
  set decisionSelectionItems(value: any) {
    this._decisionSelectionItems = value;
  }

  _statusSelectionItems: any;
  get statusSelectionItems() { return this._statusSelectionItems };
  set statusSelectionItems(value: any) {
    this._statusSelectionItems = value;
  }

  enabledFormFilling: boolean = false;

  enableDecisionInput: boolean = false;

  bank_accounts_single = {account:"",name:"",address:"",value:""}
  bank_accounts = [];

  representative_single = {birth_date:"",name:"",address:"",tax_number:"",mother_name:"", value:""}
  representatives = [];

  currencyCount: number;
  currencyList = [];
  accountList = [];

  valueSet = [];

  actualCompany:any;

  companyNameHistory = {name: "companies_company-name", title: "companies_company-name-history", history: [/*{name: "A példa", validity: 1513296000000}, {name: "Példa", validity: 1610409600000}, {name: "Példa 2", validity: 1573776000000}*/]}
  currencyHistory = {name: "companies_company-accounting-currency", title: "Könyvelési devizanem történet", history: [/*{name: "A példa deviza", validity: "2018.10.31"}, {name: "Példa", validity: "2023.01.01"}*/]}
  shortNameHistory = {name: "companies_short-name", title: "Rövid név történet", history: [/*{name: "A példa rövid", validity: "2017.10.31"}, {name: "Példa", validity: "2022.01.01"}*/]}
  mainActivityHistory = {name: "companies_main-activity", title: "Főtevékenység történet", history: [/*{name: "A példa főtevékenyéség", validity: "2016.10.31"}, {name: "Példa", validity: "2021.01.01"}*/]}
  activityStartHistory = {name: "companies_activity-start", title: "Tevékenység kezdete történet", history: [/*{name: "A példa kezdet", validity: "2015.10.31"}, {name: "Példa", validity: "2020.01.01"}*/]}
  companyBankAccount = {name: "Bankszámla", title: "Bankszámlák", history: [/*{name: "A példa kezdet", validity: "2015.10.31"}, {name: "Példa", validity: "2020.01.01"}*/]}
  companyRepresentatives = {name: "companies_company-representative", title: "companies_company-representatives", history: [/*{name: "A példa kezdet", validity: "2015.10.31"}, {name: "Példa", validity: "2020.01.01"}*/]}

  set company(value: Company) {
    console.log(value);
    this.companyForm.patchValue(value);
    console.log(this.companyForm.value);
    this.companyForm.controls.signatoryAuditor.setValue(value.signer_coworker_id);
    this.actualCompany = value;
    value.bank_accounts.forEach(acc => {
      let singleAccount = Object.create(this.bank_accounts_single);
      singleAccount['name'] = acc.name;
      singleAccount['orig_name'] = acc.name;
      singleAccount['address'] = acc.address;
      singleAccount['account'] = acc.account;
      singleAccount['value'] = acc.account;
      singleAccount['is_primary'] = acc.is_primary;
      if(acc.is_primary){
        console.log("isprimary");
        this.companyForm.controls.primary_bank_account_account.setValue(acc.account);
        this.companyForm.updateValueAndValidity();
      }
      this.bank_accounts.push(singleAccount);
    })
    value.representatives.forEach(rep => {
      let singleRep = Object.create(this.representative_single);
      singleRep['name'] = rep.name;
      singleRep['address'] = rep.address;
      singleRep['mother_name'] = rep.mother_name;
      singleRep['tax_number'] = rep.tax_number;
      singleRep['is_primary'] = rep.is_primary;
      singleRep['value'] = rep.tax_number;
      if(rep.is_primary){
        this.companyForm.controls.primary_representative_tax_number.setValue(rep.tax_number);
        this.companyForm.updateValueAndValidity();
      }
      singleRep['birth_date'] = rep.birth_date;
      this.representatives.push(singleRep);
    })
  }

  constructor(private cdRef:ChangeDetectorRef, private pipeParse: DateParsePipe, private pipeFormat: DateFormatPipe, private _adapter: DateAdapter<any>, public dialogRef: MatDialogRef<CompanyEditorComponent>, private formBuilder: FormBuilder, private service: CompanyService, private dialogService: MatDialog, private el: ElementRef, private translateService: TranslateService) {
    dialogRef.disableClose = true;
  }

//  dateMask = localStorage.getItem("language") == "hu_HU" ? "0000. 00. 00." : "00/00/0000";
//  placeholderMask = localStorage.getItem("language") == "hu_HU" ? "éééé. hh. nn." : "dd/mm/yyyy";

  // @ViewChild("input_valid_from") inputValidFrom: ElementRef;

  ngAfterViewInit(): void{
    //console.log(this.inputValidFrom);
    //new Date(new Date(this.pipeFormat.transform(this.companyForm.get("valid_from").value) * 1000).getTime() - new Date().getTimezoneOffset()*60*1000).toISOString().split("T")[0]
    //this.inputValidFrom.nativeElement.value = this.pipeFormat.transform(this.companyForm.get("valid_from").value*1000);
    // this.inputValidFrom.nativeElement.value = this.pipeFormat.transform(this.companyForm.get("valid_from").value*1000);
  }

  ngAfterContentInit(): void{
    this._adapter.setLocale(localStorage.getItem("language") == "hu_HU"?'hu':'en');
    /*this.countryDims.forEach(singleDim => {
      if(this.actualCompany[singleDim.dim_code])this.valueSet[singleDim.dim_code] = this.actualCompany[singleDim.dim_code];
      if(this.actualCompany['bank_accounts'] && this.actualCompany['bank_accounts'].length>0){
        this.actualCompany['bank_accounts'].forEach(bank=>{
          if(bank.is_primary)this.companyForm.get('primary_bank_account_account').setValue(bank.account);
        })
      }
      if(this.actualCompany['representatives'] && this.actualCompany['representatives'].length>0){
        this.actualCompany['representatives'].forEach(representative=>{
          if(representative.is_primary)this.companyForm.get('primary_representative_tax_number').setValue(representative.name);
        })
      }
    })*/
  }
  ngOnInit(): void {

//    if (this.companyForm.controls.id.value){
//      this.companyForm.get("valid_from").setValue(new Date(new Date(this.companyForm.get("valid_from").value * 1000).getTime() - new Date().getTimezoneOffset()*60*1000).toISOString().split("T")[0])
//    }

    //this.retrieveCompanyData();

    this.loadCurrencies();

    console.log(this.actualCompany);
    if(this.actualCompany){
      console.log("id");
      this.valueSet["short_name"] = this.actualCompany.short_name;
      this.valueSet["postal_address"] = this.actualCompany.postal_address;

    }

    this.sortByDate(this.companyNameHistory.history);
    this.sortByDate(this.currencyHistory.history);
    this.sortByDate(this.shortNameHistory.history);
    this.sortByDate(this.mainActivityHistory.history);
    this.sortByDate(this.activityStartHistory.history);
  
    this.companyForm.get("status").disable();
    this.companyForm.get("decision").disable();

    if(!this.companyForm.controls.id.value){
      console.log("van id?");
      this.companyForm.get("long_name").disable();
      this.companyForm.get("currency_iso_code").disable();
/*      this.companyForm.get("short_name").disable();
      this.companyForm.get("mainActivity").disable();
      this.companyForm.get("valid_from").disable();
      this.companyForm.get("primary_bank_account_account").disable();
      this.companyForm.get("primary_representative_tax_number").disable();
      this.companyForm.get("signatoryAuditor").disable();*/
    } else {
      this.enabledFormFilling = true;
    }


    //TODO decision ha nem új és nem pending?
    if(this.companyForm.controls.id.value && this.companyForm.controls.customer_status_sid.value == 'CUS_STAT_WAITING'){
      this.enableDecisionInput = true;
      this.companyForm.get("decision").enable();
    }
    console.log(this.companyForm.value);
    this.cdRef.detectChanges();
    console.log(this.bank_accounts);
  }

  sortByDate(data){
    data.sort(function(a,b){return +a.validity - +b.validity;});
  }

  createDateString(date){
    return new Date(date).toLocaleDateString(localStorage.getItem("language").replace("_", "-"))
  }

  loadCurrencies(){
    this.service.getCurrencies(CredentialsService.role).subscribe((data: any) => {
      data.forEach((item: any)=>{
        item.value = item.iso_code
      })
      this.currencyList = data;
    });
  }

  setManualUpload(){
    this.enabledFormFilling = true;
    this.companyForm.get("long_name").enable();
    this.companyForm.get("currency_iso_code").enable();
/*    this.companyForm.get("short_name").enable();
    this.companyForm.get("mainActivity").enable();
    this.companyForm.get("valid_from").enable();
    this.companyForm.get("primary_bank_account_account").enable();
    this.companyForm.get("primary_representative_tax_number").enable();
    this.companyForm.get("signatoryAuditor").enable();*/
  }

  retrieveCompanyData(){
    this.service.getCustomerInfo({tax_number: this.companyForm.controls.tax_number.value}).subscribe((data)=>{
      let customerInfo = data.payload.content;
      console.log("customerinfo: ", customerInfo);
      console.log(this._countryDims);
      if (customerInfo) {
        this.companyForm.get("hu_tax_number").setValue(customerInfo.tax_number);
        this.companyForm.get("long_name").setValue(customerInfo.long_name);
        this.companyForm.get("currency_iso_code").setValue(customerInfo.currency_iso_code);
        this.companyForm.get("company_registration_number").setValue(customerInfo.company_registration_number);
        this.valueSet["short_name"] = customerInfo.short_name;
        this.valueSet["postal_address"] = customerInfo.address;
        delete customerInfo["tax_number"];
        delete customerInfo["email_address"];
        
        //this.companyForm.get("mainActivity").setValue();
        this.companyForm.get("valid_from").setValue(customerInfo.valid_from);
 //       this.companyForm.get("signatoryAuditor").setValue(customerInfo.signatoryAuditor);
        this.companyForm.get("is_active").setValue(customerInfo.is_active);
        //this.companyForm.patchValue(customerInfo);
        this.bank_accounts = [];
        if(customerInfo.bank_accounts){
          customerInfo.bank_accounts.forEach(account => {
            let singleAccount = Object.create(this.bank_accounts_single);
            if(account.is_primary){
            //  this.companyForm.get("primary_bank_account_account").setValue(account.name);
            }
            singleAccount.account = account.account;
            singleAccount.name = account.name;
            singleAccount.is_primary = account.is_primary;
            singleAccount.address = account.address;
            singleAccount.value = account.account;
            this.bank_accounts.push(singleAccount);
          })
        }
        this.companyForm.get("primary_bank_account_account").updateValueAndValidity();
        this.companyForm.get("primary_bank_account_account").setValue(customerInfo.primary_bank_account_account);
        if(this.companyForm.controls.is_active.value == false){
        }
        else{
          this.enableDecisionInput = false;
          this.companyForm.get("decision").disable();
        }
        this.representatives = [];
        if(customerInfo.representatives){
          // console.log(customerInfo.representatives);
          customerInfo.representatives.forEach(repr => {
            let singleRepr = Object.create(this.representative_single);
            singleRepr.address = repr.address;
            singleRepr.name = repr.name;
            singleRepr.mother_name = repr.mother_name;
            singleRepr.birth_date = repr.birth_date;
            singleRepr.tax_number = repr.tax_number;
            singleRepr.is_primary = repr.is_primary;
            singleRepr.value = repr.tax_number;
            // console.log(singleRepr);
            this.representatives.push(singleRepr);
          })
          this.companyForm.get("primary_representative_tax_number").updateValueAndValidity();
          this.companyForm.get("primary_representative_tax_number").setValue(customerInfo.primary_representative_tax_number);
        }
      
        this.companyForm.updateValueAndValidity();
        this.setManualUpload();
      }
    })

  }

  showAccounts(){
    const dialog = this.dialogService.open(CompanyBankaccountComponent);
    dialog.componentInstance.bankAccounts = this.bank_accounts;
    dialog.afterClosed().subscribe(() => {
      if(dialog.componentInstance.saving){
        this.bank_accounts = dialog.componentInstance.bankAccounts;
        this.accountList = [];
        this.bank_accounts.forEach(acc=>{
          acc.value=acc.account;
        })
      }
    })
  }

  showRepresentatives(){
    const dialog = this.dialogService.open(CompanyRepresentativesComponent);
    dialog.componentInstance.representatives = this.representatives;
    dialog.afterClosed().subscribe(() => {
      if(dialog.componentInstance.saving){
        this.representatives = dialog.componentInstance.representatives;
        this.accountList = [];
        this.representatives.forEach(rep=>{
          rep.value=rep.tax_number;
        })
      }
    })
  }

  showHistory(data, isMore){
    const dialog = this.dialogService.open(CompanyHistoryComponent);
    dialog.componentInstance.data = data;
    dialog.componentInstance.isMore = isMore;
  }

  saveCompany(isPending = false) {
    const invalid = [];
    const controls = this.companyForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    console.log(invalid);
    if (this.companyForm.valid) {
      console.log(this.companyForm.value);
      if (this.companyForm.controls.id.value) {
        const dialog = this.dialogService.open(OkDialogComponent);
        this.translateService.get('companies_overwrite-confirmation').subscribe((value)=>dialog.componentInstance.title = value)
        this.translateService.get('1').subscribe((value)=>dialog.componentInstance.positiveButtonText = value)
        this.translateService.get('0').subscribe((value)=>dialog.componentInstance.negativeButtonText = value)
        dialog.afterClosed().subscribe(response => {
          console.log(response);
          if (response) {
            let form = JSON.parse(JSON.stringify((this.companyForm.getRawValue())))
            console.log(form);
            //form.valid_from = new Date(this.pipeParse.transform(this.inputValidFrom.nativeElement.value)).getTime() / 1000;
            form.signer_coworker_id = this.companyForm.controls.signatoryAuditor.value;
            if(this.bank_accounts.length>0){
              form['bank_accounts'] = [];
              this.bank_accounts.forEach(acc=>{
                let singleAccount = {};
                singleAccount['account'] = acc.account;
                singleAccount['is_primary'] = false;
                singleAccount['name'] = acc.name;
                singleAccount['address'] = acc.address;
                form['bank_accounts'].push(singleAccount);
              })
            }

            if(this.representatives.length>0){
              let selectedRepName = form.primary_representative_tax_number;
              form['representatives'] = [];
              this.representatives.forEach(rep=>{
                let singleRepresentative = {};
                singleRepresentative['mother_name'] = rep.mother_name;
                singleRepresentative['name'] = rep.name;
                singleRepresentative['address'] = rep.address;
                singleRepresentative['birth_date'] = rep.birth_date;
                singleRepresentative['tax_number'] = rep.tax_number;
                form['representatives'].push(singleRepresentative);
                if (selectedRepName == rep.name) {
                  form.primary_representative_tax_number = rep.tax_number;
                }
                console.log(form['representatives']);
              })
            }else{
              form['representatives'] = [];
            }

            if (isPending){
              form.customer_status_sid = "CUS_STAT_PENDING"
            }else{
              if(form.customer_status_sid == "CUS_STAT_PENDING"){
                form.customer_status_sid = "CUS_STAT_WAITING"
              }else if(form.customer_status_sid == "CUS_STAT_WAITING"){
                form.customer_status_sid = "CUS_STAT_ACTIVE"
              }
            };
            for (const key in this.valueSet) {
              if (this.valueSet.hasOwnProperty(key)) {
                form[key]=this.valueSet[key];
              }
            }
            // form.primary_representative_tax_number = form['representatives'][0].tax_number;
            console.log(form);
            
            this.service.modifyCompany(form).subscribe((response) => {
              this.dialogRef.close(response);
            });
          }

        })
      }
      else {
        let form = JSON.parse(JSON.stringify((this.companyForm.getRawValue())))
        //form.valid_from = new Date(this.pipeParse.transform(this.inputValidFrom.nativeElement.value)).getTime() / 1000;
        form.signer_coworker_id = this.companyForm.controls.signatoryAuditor.value;

        if(this.bank_accounts.length>0){
          form['bank_accounts'] = [];
          this.bank_accounts.forEach(acc=>{
            let singleAccount = {};
            singleAccount['account'] = acc.account;
            singleAccount['is_primary'] = false;
            singleAccount['name'] = acc.name;
            singleAccount['address'] = acc.address;
            form['bank_accounts'].push(singleAccount);
          })
        }

        if(this.representatives.length>0){
          form['representatives'] = [];
          this.representatives.forEach(rep=>{
            let singleRepresentative = {};
            singleRepresentative['mother_name'] = rep.mother_name;
            singleRepresentative['is_primary'] = false;
            singleRepresentative['name'] = rep.name;
            singleRepresentative['address'] = rep.address;
            singleRepresentative['birth_date'] = rep.birth_date;
            singleRepresentative['tax_number'] = rep.tax_number;
            form['representatives'].push(singleRepresentative);
          })
        }

        
        /*if(form.primary_bank_account_account!=null) {
          form['bank_accounts'] = [];
          let singleAccount = {};
          singleAccount['account'] = form.primary_bank_account_account;
          singleAccount['is_primary'] = true;
          singleAccount['name'] = 'primary';
          singleAccount['address'] = 'unknown';
          form['bank_accounts'].push(singleAccount);
        }
        if(form.primary_representative_tax_number!=null) {
          form['representatives'] = [];
          let singleRepresentative = {};
          singleRepresentative['name'] = form.primary_representative_tax_number;
          singleRepresentative['is_primary'] = true;
          singleRepresentative['address'] = 'unknown';
          singleRepresentative['mother_name'] = 'unknown';
          singleRepresentative['tax_number'] = 'unknown';
          singleRepresentative['birth_date'] = 0;
          form['representatives'].push(singleRepresentative);
        }*/

        if (isPending){
          form.customer_status_sid = "CUS_STAT_PENDING"
        }
        else{
          form.customer_status_sid = "CUS_STAT_WAITING"
        }
        for (const key in this.valueSet) {
          if (this.valueSet.hasOwnProperty(key)) {
            form[key]=this.valueSet[key];
          }
        }

        this.service.createCompany(form).subscribe((response) => {
          this.dialogRef.close(response);
        });
      }
    } else {
      this.companyForm.markAllAsTouched();
      let firstInvalidControl = this.el.nativeElement.querySelector("form .ng-invalid");
      this.scrollToFirstInvalidControl(firstInvalidControl);
    }
  }

  private scrollToFirstInvalidControl(firstInvalidControl) {
    if (firstInvalidControl) {
      firstInvalidControl.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  reject(){
    if(this.companyForm.dirty) {
      const dialog = this.dialogService.open(OkDialogComponent);
      this.translateService.get('companies_reject-modification-exit').subscribe((value)=>dialog.componentInstance.title = value)
      this.translateService.get('1').subscribe((value)=>dialog.componentInstance.positiveButtonText = value)
      this.translateService.get('0').subscribe((value)=>dialog.componentInstance.negativeButtonText = value)
      dialog.afterClosed().subscribe(response => {
        if (response) {
          this.dialogRef.close();
        }
      })
    }else{
      this.dialogRef.close();
    }
  }
}

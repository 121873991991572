import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ProgressService} from './progress.service';
import { CountAnswer, PaginatedAnswer, PaginatedRequest } from '../data/response';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { WorkflowTemplate } from 'src/app/data/workflow-template';
import { Coworker } from '../data/coworker';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class WfTemplateService extends BaseService<WorkflowTemplate> implements OnInit {

  translatableTexts: any[] = [
    {value: "error_message_workflow_templates_list_unsuccessful", name: ""},
    {value: "error_message_workflow_template_get_unsuccessful", name: ""},
    {value: "error_message_workflow_template_delete_unsuccessful", name: ""},
    {value: "error_message_workflow_template_create_unsuccessful", name: ""},
    {value: "error_message_workflow_template_update_unsuccessful", name: ""},
    {value: "error_message_error_getting_workflow_templates", name: ""},
    {value: "error_message_error_getting_workflow_template", name: ""},
    {value: "error_message_error_getting_coworkers", name: ""},
    {value: "error_message_error_getting_workflow_element", name: ""},
    {value: "error_message_error_saving_workflow_element", name: ""},
    {value: "error_message_error_getting_workflow_element_types", name: ""},
    {value: "error_message_error_getting_workflow_element_timing_codes", name: ""},
    {value: "error_message_error_getting_workflow_element_groups", name: ""},
    {value: "error_message_workflow_element_delete_unsuccessful", name: ""}
  ];

  constructor(httpClient: HttpClient, snackBar: MatSnackBar, progressService: ProgressService, private translateService: TranslateService) {
    super(
      {
        listUrl: '/owner',
        getUrl: '/owner',
        createUrl: '/owner/wftemplate',
        deleteUrl: '/owner/wftemplate',
        updateUrl: '/owner/wftemplate'
      },
      {
        listError: 'A workflow sablonok letöltése sikertelen',
        getError: 'A workflow sablon letöltése sikertelen',
        deleteError: 'A workflow sablon törlése sikertelen',
        createError: 'A workflow sablon létrehozása sikertelen',
        updateError: 'A workflow sablon módosítása sikertelen'
      },
      httpClient, snackBar, progressService);

  }

  ngOnInit(): void {
    this.translateService.stream([
      'error_message_workflow_templates_list_unsuccessful',
      'error_message_workflow_template_get_unsuccessful',
      'error_message_workflow_template_delete_unsuccessful',
      'error_message_workflow_template_create_unsuccessful',
      'error_message_workflow_template_update_unsuccessful',
      'error_message_error_getting_workflow_templates',
      'error_message_error_getting_workflow_template',
      'error_message_error_getting_coworkers',
      'error_message_error_getting_workflow_element',
      'error_message_error_saving_workflow_element',
      'error_message_error_getting_workflow_element_types',
      'error_message_error_getting_workflow_element_timing_codes',
      'error_message_error_getting_workflow_element_groups',
      'error_message_workflow_element_delete_unsuccessful'
    ]).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "error_message_workflow_templates_list_unsuccessful", name: res["error_message_workflow_templates_list_unsuccessful"]},
        {value: "error_message_workflow_template_get_unsuccessful", name: res["error_message_workflow_template_get_unsuccessful"]},
        {value: "error_message_workflow_template_delete_unsuccessful", name: res["error_message_workflow_template_delete_unsuccessful"]},
        {value: "error_message_workflow_template_create_unsuccessful", name: res["error_message_workflow_template_create_unsuccessful"]},
        {value: "error_message_workflow_template_update_unsuccessful", name: res["error_message_workflow_template_update_unsuccessful"]},
        {value: "error_message_error_getting_workflow_templates", name: res["error_message_error_getting_workflow_templates"]},
        {value: "error_message_error_getting_workflow_template", name: res["error_message_error_getting_workflow_template"]},
        {value: "error_message_error_getting_coworkers", name: res["error_message_error_getting_coworkers"]},
        {value: "error_message_error_getting_workflow_element", name: res["error_message_error_getting_workflow_element"]},
        {value: "error_message_error_saving_workflow_element", name: res["error_message_error_saving_workflow_element"]},
        {value: "error_message_error_getting_workflow_element_types", name: res["error_message_error_getting_workflow_element_types"]},
        {value: "error_message_error_getting_workflow_element_timing_codes", name: res["error_message_error_getting_workflow_element_timing_codes"]},
        {value: "error_message_error_getting_workflow_element_groups", name: res["error_message_error_getting_workflow_element_groups"]},
        {value: "error_message_workflow_element_delete_unsuccessful", name: res["error_message_workflow_element_delete_unsuccessful"]}
      ];
    });

    this.errorMessages.listError = this.translatableTexts.find(element => element.value === "error_message_workflow_templates_list_unsuccessful").name
    this.errorMessages.getError = this.translatableTexts.find(element => element.value === "error_message_workflow_template_get_unsuccessful").name
    this.errorMessages.deleteError = this.translatableTexts.find(element => element.value === "error_message_workflow_template_delete_unsuccessful").name
    this.errorMessages.createError = this.translatableTexts.find(element => element.value === "error_message_workflow_template_create_unsuccessful").name
    this.errorMessages.updateError = this.translatableTexts.find(element => element.value === "error_message_workflow_template_update_unsuccessful").name
  }

  listWfTemplates(privLevel): Observable<PaginatedAnswer<WorkflowTemplate>> {
    const task = this.progressService.addTask(this.constructor.name + '_paginated_list');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    let payload = btoa(JSON.stringify({"toggle": {
      "operator": "AND",
      "filters": [
        {
            "column": "is_active",
            "value": 1
        }]
      }})
    )
    return this.httpClient.get<PaginatedAnswer<WorkflowTemplate>>(environment.serverEndpoint + '/'+privLevel+'/wftemplates?payload=' + payload, {headers}).pipe(
        catchError(error => {
          this.snackBar.open(this.errorMessages.listError, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }

  deleteTemplate(id){
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        id: id
      },
    };
    return this.httpClient.delete<any>(environment.serverEndpoint + '/owner/wftemplate', options)
    .toPromise()
    .catch(error => {
      this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_workflow_template_delete_unsuccessful").name, null, {panelClass: 'error-snackbar'});
      console.log(error);
      return Promise.reject(error);
    })
  }

  getTemplate(id){
    let body = {
      id: id
    }
    let payload = btoa(JSON.stringify(body));
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.get<any>(environment.serverEndpoint + '/owner/wftemplate?payload=' + payload, {headers})
    .toPromise()
    .catch(error => {
      this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_workflow_template").name, null, {panelClass: 'error-snackbar'});
      console.log(error);
      return Promise.reject(error);
    })
  }

  listCoworkers(privLevel): Observable<PaginatedAnswer<Coworker>> {
    const task = this.progressService.addTask(this.constructor.name + '_paginated_list');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    
    return this.httpClient.get<PaginatedAnswer<Coworker>>(environment.serverEndpoint + '/'+privLevel+'/coworkers?payload=', {headers}).pipe(
        catchError(error => {
          this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_coworkers").name, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }

  modifyWfTemplate(body) {
    const task = this.progressService.addTask(this.constructor.name + '_modify_wf_template');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.put<PaginatedAnswer<any>>(environment.serverEndpoint + '/owner/wftemplate', body, {headers: headers}).pipe(
        catchError(error => {
          this.snackBar.open(this.errorMessages.updateError, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }

  getWfElements(){
    return this.httpClient.get<any>(environment.serverEndpoint + '/owner/wfelements')
    .toPromise()
    .catch(error => {
      this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_workflow_element").name, null, {panelClass: 'error-snackbar'});
      console.log(error);
      return Promise.reject(error);
    })
  }

  getWfElementsByWfTemplate(id){
    let request = {
      "select": {
        "operator": "AND",
        "filters": [{
            "column": "workflow_template_id",
            "relation": "=",
            "value": id
        }]
    }
    }
    let payload = btoa(JSON.stringify(request));
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.get<any>(environment.serverEndpoint + '/owner/wfelements?payload=' + payload, {headers})
    .toPromise()
    .catch(error => {
      this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_workflow_element").name, null, {panelClass: 'error-snackbar'});
      console.log(error);
      return Promise.reject(error);
    })
  }

  getWfElement(id){
    let body = {
      id: id
    }
    let payload = btoa(JSON.stringify(body));
    const task = this.progressService.addTask(this.constructor.name + 'create_wf_element');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.get<PaginatedAnswer<any>>(environment.serverEndpoint + '/owner/wfelement?payload=' + payload, {headers: headers}).pipe(
        catchError(error => {
          this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_workflow_element").name, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }

  createWfElement(body){
    const task = this.progressService.addTask(this.constructor.name + 'create_wf_element');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.post<PaginatedAnswer<any>>(environment.serverEndpoint + '/owner/wfelement', body, {headers: headers}).pipe(
        catchError(error => {
          this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_saving_workflow_element").name, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }

  modifyWfElement(body){
    const task = this.progressService.addTask(this.constructor.name + 'create_wf_element');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.put<PaginatedAnswer<any>>(environment.serverEndpoint + '/owner/wfelement', body, {headers: headers}).pipe(
        catchError(error => {
          this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_saving_workflow_element").name, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }

    addElementCoworker(body){
        const task = this.progressService.addTask(this.constructor.name + 'add_coworker_to_wfe');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.httpClient.post<PaginatedAnswer<any>>(environment.serverEndpoint + '/user/wfecoworkers', body, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_saving_workflow_element").name, null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    saveWF(body){
        const task = this.progressService.addTask(this.constructor.name + 'wf_save');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.httpClient.post<any>(environment.serverEndpoint + '/user/wf', body, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_saving_workflow").name, null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

  getWfeTypes(){
    const task = this.progressService.addTask(this.constructor.name + 'get_wfe_types');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.get<any>(environment.serverEndpoint + '/common/wfetypes', {headers: headers}).pipe(
        catchError(error => {
          this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_workflow_element_types").name, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }

  getWfeTimes(){
    const task = this.progressService.addTask(this.constructor.name + 'get_wfeg_times');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.get<any>(environment.serverEndpoint + '/common/wfegtimes', {headers: headers}).pipe(
        catchError(error => {
          this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_workflow_element_timing_codes").name, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }

  getWfElementGroups(privLevel){
      const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
      let payload = btoa(JSON.stringify({locale: localStorage.getItem('language')}));
      return this.httpClient.get<any>(environment.serverEndpoint + '/'+privLevel+'/wfelementgroups?payload=' + payload, {headers})
    .toPromise()
    .catch(error => {
      this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_workflow_element_groups").name, null, {panelClass: 'error-snackbar'});
      console.log(error);
      return Promise.reject(error);
    })
  }

  
  deleteWfElement(id){
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        id: id
      },
    };
    return this.httpClient.delete<any>(environment.serverEndpoint + '/owner/wfelement', options)
    .toPromise()
    .catch(error => {
      this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_workflow_element_delete_unsuccessful").name, null, {panelClass: 'error-snackbar'});
      console.log(error);
      return Promise.reject(error);
    })
  }

  
}

<h2 mat-dialog-title>{{auditorForm.controls.id.value ? ('auditors_client-datas-modification' | translate) : ('auditors_client-datas' | translate)}} {{auditorForm.controls.business_code.value ? '(' + auditorForm.controls.business_code.value + ')' : ''}}</h2>
<mat-dialog-content>
    <form [formGroup]="auditorForm">
        <div class="row">
            <div class="col-12 col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>{{'auditors_name' | translate}}</mat-label>
                    <input matInput formControlName="name" required>
                    <mat-error *ngIf="auditorForm.controls.name.hasError('required')">
                        {{'error_message_name_cannot_be_empty' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>{{'auditors_identifier-code' | translate}}</mat-label>
                    <input matInput formControlName="business_code" required>
                    <mat-error *ngIf="auditorForm.controls.business_code.hasError('required')">
                        {{'error_message_identifier_code_cannot_be_empty' | translate}}
                    </mat-error>
                    <mat-error *ngIf="auditorForm.controls.business_code.hasError('maxlength')">
                        {{'error_message_identifier_code_max_length_is_20_chars' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6">
                <app-select-search [selectionItems]="countrySelectionList" [selectionType]="'singleSelect'" [placeholder]="'auditors_country' | translate" [selectCtrl]="auditorForm.controls.country_iso_code" [required]="true">
                </app-select-search>
            </div>
            <div class="col-12 col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>
                        {{'auditors_postal-code' | translate}}
                    </mat-label>
                    <input matInput formControlName="postal_code" required>
                    <mat-error *ngIf="auditorForm.controls.postal_code.hasError('required')">
                        {{'error_message_postal_code_cannot_be_empty' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>
                        {{'auditors_city' | translate}}
                    </mat-label>
                    <input matInput formControlName="city" required>
                    <mat-error *ngIf="auditorForm.controls.city.hasError('required')">
                        {{'error_message_city_cannot_be_empty' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>{{'auditors_postal-address' | translate}}</mat-label>
                    <input matInput formControlName="postal_address" required>
                    <mat-error *ngIf="auditorForm.controls.postal_address.hasError('required')">
                        {{'error_message_postal_address_cannot_be_empty' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>{{'auditors_email-address' | translate}}</mat-label>
                    <input matInput formControlName="email_address" required>
                    <mat-error *ngIf="auditorForm.controls.email_address.hasError('required')">
                        {{'error_message_email_address_cannot_be_empty' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>{{'auditors_chamber-code' | translate}}</mat-label>
                    <input matInput formControlName="chamber_code" required>
                    <mat-error *ngIf="auditorForm.controls.chamber_code.hasError('required')">
                        {{'error_message_chamber_code_cannot_be_empty' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>{{'auditors_tax-number' | translate}}</mat-label>
                    <input matInput formControlName="tax_number" required>
                    <mat-error *ngIf="auditorForm.controls.tax_number.hasError('required')">
                        {{'error_message_tax_number_cannot_be_empty' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>
                        {{'auditors_audit-software' | translate}}
                    </mat-label>
                    <mat-select formControlName="auditor_program_id" required>
                        <mat-option *ngFor="let auditorSoftware of auditorSoftwareList"
                                    [value]="auditorSoftware.id">{{auditorSoftware.name}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="auditorForm.controls.auditor_program_id.hasError('required')">
                        {{'error_message_audit_software_cannot_be_empty' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row" style="justify-content: center;">
            <div class="col-12 col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>{{'auditors_registration-code' | translate}}</mat-label>
                    <input matInput formControlName="registration_code" required>
                    <mat-error *ngIf="auditorForm.controls.registration_code.hasError('required')">
                        {{'error_message_registration_code_cannot_be_empty' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>{{'auditors_localadmin_name' | translate}}</mat-label>
                    <input matInput formControlName="local_admin_name" required>
                    <mat-error *ngIf="auditorForm.controls.local_admin_name.hasError('required')">
                        {{'error_message_localadmin_name_cannot_be_empty' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>

        </div>
        <div class="row" style="justify-content: center;">
            <div class="col-12 col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>{{'auditors_licenc-quantity' | translate}}</mat-label>
                    <input matInput type="number" formControlName="licenc_quantity" required>
                    <mat-error *ngIf="auditorForm.controls.licenc_quantity.hasError('required')">
                        {{'error_message_licence_quantity_cannot_be_empty' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>{{'auditors_licenc-valid_to' | translate}}</mat-label>
                    <input matInput [matDatepicker]="pickerLicencValidTo" [placeholder]="'dateformat-placeholder' | translate" [imask]="{mask: 'dateformat-mask' | translate}" #licenc_valid_to>
                    <mat-datepicker-toggle matSuffix [for]="pickerLicencValidTo"></mat-datepicker-toggle>
                    <mat-datepicker #pickerLicencValidTo></mat-datepicker>
                    <!-- input matInput formControlName="licenc_valid_to" required -->
                    <mat-error *ngIf="auditorForm.controls.licenc_valid_to.hasError('required')">
                        {{'error_message_tax_number_cannot_be_empty' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>{{'auditors_reply-address' | translate}}</mat-label>
                    <input matInput formControlName="reply_address" >
                </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
                <app-select-search [selectionItems]="auditorStatuses" [selectionType]="'singleSelect'" [placeholder]="'auditors_status' | translate" [selectCtrl]="auditorForm.controls.auditor_status_sid" [required]="true" [disabled]="auditorForm.get('auditor_status_sid').disabled">
                </app-select-search>
            </div>
        </div>        
    </form>
</mat-dialog-content>
<mat-dialog-actions [style.justify-content]="'space-around'">
    <button mat-button class="positiveButton" (click)="saveAuditor()">
        {{auditorForm.controls.id.value ? ('auditors_save-modification' | translate) : ('auditors_client-recording' | translate)}}
    </button>
    <button mat-button class="negativeButton" (click)="dialogRef.close()">
        {{auditorForm.controls.id.value ? ('auditors_cancel' | translate) : ('auditors_cancel' | translate)}}</button>
</mat-dialog-actions>

import {Injectable, OnInit} from "@angular/core";
import {BaseService} from "./base.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ProgressService} from "./progress.service";
import {Observable, throwError} from "rxjs";
import {catchError, finalize} from "rxjs/operators";
import {environment} from "src/environments/environment";
import {Template} from "../data/template";
import {TranslateService} from "@ngx-translate/core";


@Injectable({
  providedIn: 'root'
})
export class TemplateService extends BaseService<Template> implements OnInit {

  translatableTexts: any[] = [
    {value: "error_message_templates_list_unsuccessful", name: ""},
    {value: "error_message_template_get_unsuccessful", name: ""},
    {value: "error_message_template_delete_unsuccessful", name: ""},
    {value: "error_message_template_create_unsuccessful", name: ""},
    {value: "error_message_template_update_unsuccessful", name: ""},
    {value: "error_message_error_getting_subtemplates", name: ""},
    {value: "error_message_error_getting_template", name: ""}
  ];

  constructor(httpClient: HttpClient, snackBar: MatSnackBar, progressService: ProgressService, private translateService: TranslateService) {
    super(
      {
        listUrl: '/owner',
        getUrl: '/owner',
        createUrl: '/owner/templates',
        deleteUrl: '',
        updateUrl: ''
      },
      {
        listError: 'A sablonok letöltése sikertelen',
        getError: 'A sablon letöltése sikertelen',
        deleteError: 'A sablon törlése sikertelen',
        createError: 'A sablon létrehozása sikertelen',
        updateError: 'A sablon módosítása sikertelen'
      },
      httpClient, snackBar, progressService);

  }

  ngOnInit(): void {
    this.translateService.stream([
      'error_message_templates_list_unsuccessful',
      'error_message_template_get_unsuccessful',
      'error_message_template_delete_unsuccessful',
      'error_message_template_create_unsuccessful',
      'error_message_template_update_unsuccessful',
      'error_message_error_getting_subtemplates',
      'error_message_error_getting_template'
    ]).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "error_message_templates_list_unsuccessful", name: res["error_message_templates_list_unsuccessful"]},
        {value: "error_message_template_get_unsuccessful", name: res["error_message_template_get_unsuccessful"]},
        {value: "error_message_template_delete_unsuccessful", name: res["error_message_template_delete_unsuccessful"]},
        {value: "error_message_template_create_unsuccessful", name: res["error_message_template_create_unsuccessful"]},
        {value: "error_message_template_update_unsuccessful", name: res["error_message_template_update_unsuccessful"]},
        {value: "error_message_error_getting_subtemplates", name: res["error_message_error_getting_subtemplates"]},
        {value: "error_message_error_getting_template", name: res["error_message_error_getting_template"]}
      ];
    });

    this.errorMessages.listError = this.translatableTexts.find(element => element.value === "error_message_templates_list_unsuccessful").name
    this.errorMessages.getError = this.translatableTexts.find(element => element.value === "error_message_template_get_unsuccessful").name
    this.errorMessages.deleteError = this.translatableTexts.find(element => element.value === "error_message_template_delete_unsuccessful").name
    this.errorMessages.createError = this.translatableTexts.find(element => element.value === "error_message_template_create_unsuccessful").name
    this.errorMessages.updateError = this.translatableTexts.find(element => element.value === "error_message_template_update_unsuccessful").name
  }

  getSubtemplates(privLevel){
    return this.httpClient.get<any>(environment.serverEndpoint + '/'+privLevel+'/subtemplates')
    .toPromise()
    .catch(error => {
      this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_subtemplates").name, null, {panelClass: 'error-snackbar'});
      console.log(error);
      return Promise.reject(error);
    })
  }

    listSubTemplates(privLevel){
        const task = this.progressService.addTask(this.constructor.name + '_paginated_list');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.httpClient.get<Template>(environment.serverEndpoint + '/'+privLevel+'/subtemplates', {headers}).pipe(
            catchError(error => {
                this.snackBar.open(this.errorMessages.listError, null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

  listTemplates(privLevel): Observable<Template> {
    const task = this.progressService.addTask(this.constructor.name + '_paginated_list');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.get<Template>(environment.serverEndpoint + '/'+privLevel+'/templates', {headers}).pipe(
        catchError(error => {
          this.snackBar.open(this.errorMessages.listError, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }

  listUsersTemplates(): Observable<Template> {
    const task = this.progressService.addTask(this.constructor.name + '_paginated_list');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.get<Template>(environment.serverEndpoint + '/user/templates', {headers}).pipe(
        catchError(error => {
          this.snackBar.open(this.errorMessages.listError, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }

  getTemplate(template){
    let template_code = "";
    let subtemplate_code = "";
    if(template.template_code){template_code=template.template_code}else{template_code=template.path.split("/")[1]}
    if(template.subtemplate_code){subtemplate_code=template.subtemplate_code}else{subtemplate_code=template.subtemplate_id}
    let body = {
      locale: template.locale,
      subtemplate_id: +subtemplate_code,
      template_code: template_code
    }
    let payload = btoa(JSON.stringify(body));
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.get<any>(environment.serverEndpoint + '/owner/template?payload=' + payload, {headers})
    .toPromise()
    .catch(error => {
      this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_template").name, null, {panelClass: 'error-snackbar'});
      console.log(error);
      return Promise.reject(error);
    })
  }

  startESign(template, id, workflow=false, preview=false){
    let template_code = "";
    let subtemplate_code = "";
    // console.log(id);
    if(template.template_code){template_code=template.template_code}else{template_code=template.path.split("/")[1]}
    if(template.subtemplate_code){subtemplate_code=template.subtemplate_code}else{subtemplate_code=template.subtemplate_id}
    let body;
    body = {
      locale: template.locale,
      subtemplate_id: +subtemplate_code,
      template_code: template_code,
      id: id
    }
    let payload = btoa(JSON.stringify(body));
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    if(preview){
      body.is_preview = true;
      let payload = btoa(JSON.stringify(body));
      return this.httpClient.get<any>(environment.serverEndpoint + '/user/contractsign?payload=' + payload, {headers}).toPromise()
        .catch(error => {
          this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_template").name, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return Promise.reject(error);
        })
      }else{
        return this.httpClient.get<any>(environment.serverEndpoint + '/user/contractsign?payload=' + payload, {headers})
        .toPromise()
        .catch(error => {
          this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_template").name, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return Promise.reject(error);
        });
      }    
    }

    getTemplateContract(template, contractId){
      // console.log(template);
        let template_code = "";
        let subtemplate_code = "";
        if(template.template_code){template_code=template.template_code}else{template_code=template.path.split("/")[1]}
        if(template.subtemplate_code){subtemplate_code=template.subtemplate_code}else{subtemplate_code=template.subtemplate_id}
        let body = {
            locale: template.locale,
            subtemplate_id: +subtemplate_code,
            template_code: template_code,
            id: contractId
        }
        let payload = btoa(JSON.stringify(body));
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.httpClient.get<any>(environment.serverEndpoint + '/user/contracttemplate?payload=' + payload, {headers})
            .toPromise()
            .catch(error => {
                this.snackBar.open("Hiba a sablon lekérdezése közben!", null, {panelClass: 'error-snackbar'});
                console.log(error);
                return Promise.reject(error);
            })
    }

    getIndependence(body){
        let payload = btoa(JSON.stringify(body));
        console.log("Indep payload:", payload)
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.httpClient.get<any>(environment.serverEndpoint + '/user/wfindependence?payload=' + payload, {headers})
            .toPromise()
            .catch(error => {
                this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_template").name, null, {panelClass: 'error-snackbar'});
                console.log(error);
                return Promise.reject(error);
            })
    }

    createIndependence(body){
        let payload = btoa(JSON.stringify(body));
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.httpClient.get<any>(environment.serverEndpoint + '/user/wfcrtindependence?payload=' + payload, {headers})
            .toPromise()
            .catch(error => {
                this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_template").name, null, {panelClass: 'error-snackbar'});
                console.log(error);
                return Promise.reject(error);
            })
    }

    getTemplateCodes(){
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.httpClient.get<any>(environment.serverEndpoint + '/common/templates', {headers})
            .toPromise()
            .catch(error => {
                this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_templates").name, null, {panelClass: 'error-snackbar'});
                console.log(error);
                return Promise.reject(error);
            })
    }

    postTemplateFolder(body){
        const task = this.progressService.addTask(this.constructor.name + 'save_template_folder');
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.httpClient.post<any>(environment.serverEndpoint + '/owner/templatefolder', body, {headers: headers}).pipe(
            catchError(error => {
                this.snackBar.open("Hiba sablon mentési útvonalának rögzítése során!", null, {panelClass: 'error-snackbar'});
                console.log(error);
                return throwError(error);
            }),
            finalize(() => this.progressService.removeTask(task.id))
        );
    }

    getTemplateFolders(){
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.httpClient.get<any>(environment.serverEndpoint + '/owner/templatefolders', {headers})
            .toPromise()
            .catch(error => {
                this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_getting_templates").name, null, {panelClass: 'error-snackbar'});
                console.log(error);
                return Promise.reject(error);
            })
    }


}

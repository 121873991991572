<mat-toolbar color="primary">
    <div>
        <button mat-icon-button matTooltip="{{'common_title_filter_table_columns' | translate}}" (click)="filterTableColumns()">
            <mat-icon>filter_alt</mat-icon>
          </button>
        <button mat-icon-button matTooltip="{{'auditors_record-new-client' | translate}}" (click)="editAuditor()">
            <mat-icon>add</mat-icon>
        </button>
        <button mat-icon-button matTooltip="{{'auditors_modify-data' | translate}}" [disabled]="selection.selected.length !== 1" (click)="editAuditor(selection.selected[0])">
            <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button matTooltip="{{'auditors_owner-password-reset' | translate}}" [disabled]="selection.selected.length !== 1" (click)="restorePassword(selection.selected[0])">
            <mat-icon>restore</mat-icon>
        </button>
    </div>
    <div>
        <app-select-search [appearanceStyle]="'standard'" [selectionItems]="columnSelectionItems" [selectionType]="'singleSelect'" [placeholder]="'common_title_column' | translate" [selectCtrl]="columnSelectionForm.controls.columnName" [required]="false"></app-select-search>
        <mat-form-field>
            <mat-label>{{'auditors_search' | translate}}</mat-label>
            <input matInput (keydown.enter)="searchAuditor($event)" #searchInput>
            <button mat-button *ngIf="searchInput.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()"><mat-icon>close</mat-icon></button>
        </mat-form-field>
    </div>
</mat-toolbar>
<table mat-table [dataSource]="data" matSort cdkDropListOrientation="horizontal"
cdkDropList (cdkDropListDropped)="reorderColumns($event)">

    <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef cdkDragLockAxis="x" cdkDrag></th>
        <td mat-cell *matCellDef="let row">
            <mat-radio-button (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(row) : null"
                          [checked]="selection.isSelected(row)">
            </mat-radio-button>
        </td>
    </ng-container>

    <ng-container matColumnDef="business_code">
        <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDragLockAxis="x" cdkDrag>{{'auditors_identifier-code' | translate}}</th>
        <td mat-cell *matCellDef="let row"> {{row.business_code}} </td>
    </ng-container>
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDragLockAxis="x" cdkDrag>{{'auditors_name' | translate}}</th>
        <td mat-cell *matCellDef="let row"> {{row.name}} </td>
    </ng-container>
    <ng-container matColumnDef="email_address">
        <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDragLockAxis="x" cdkDrag>{{'auditors_email-address' | translate}}</th>
        <td mat-cell *matCellDef="let row"> {{row.email_address}} </td>
    </ng-container>
    <ng-container matColumnDef="city">
        <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDragLockAxis="x" cdkDrag>{{'auditors_city' | translate}}</th>
        <td mat-cell *matCellDef="let row"> {{row.city}} </td>
    </ng-container>
    <ng-container matColumnDef="tax_number">
        <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDragLockAxis="x" cdkDrag>{{'auditors_tax-number' | translate}}</th>
        <td mat-cell *matCellDef="let row"> {{row.tax_number}} </td>
    </ng-container>
    <ng-container matColumnDef="auditor_status_sid">
        <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDragLockAxis="x" cdkDrag>{{'auditors_status' | translate}}</th>
        <td mat-cell *matCellDef="let row"> {{row.auditor_status_sid | translate}} </td>
    </ng-container>
    <ng-container matColumnDef="local_admin_username">
        <th mat-header-cell *matHeaderCellDef cdkDragLockAxis="x" cdkDrag>{{'local_admin_username' | translate}}</th>
        <td mat-cell *matCellDef="let row"> {{row.local_admin_username | translate}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)" [ngClass]="{ 'selected': selection.isSelected(row)}"></tr>

    <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">{{'common_message_no_data_to_display' | translate}}</td>
    </tr>
</table>

<mat-paginator [length]="resultsLength" [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="pageSize" (page)="handlePage($event)" [showFirstLastButtons]="true"></mat-paginator>
